import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api-service/api.service';
import { API_ENDPOINTS_V2 } from 'src/assets/data-variable/api-constant-v2';

@Injectable({
  providedIn: 'root'
})
export class CommonApiService {

  constructor(private apiService: ApiService) { }

  async getAllInsurerBasicDetails() {
    try {
      const apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_V2.users}/${API_ENDPOINTS_V2.insurerBasedBasicDetails}`, {});
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getAllInsurerBasicConfigDetails(insurerName) {
    try {
      const apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_V2.users}/${API_ENDPOINTS_V2.insurerBasedDetails}/${insurerName}`, {});
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async changePassword(params) {
    try {
      const apiResponse = await this.apiService.putApiCall(`${API_ENDPOINTS_V2.insurer}/${API_ENDPOINTS_V2.changePassword}`, params);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async uploadMafGroupDocument(params) {
    try {
      const apiResponse = await this.apiService.putApiCall(`${API_ENDPOINTS_V2.quoteSummary}/${API_ENDPOINTS_V2.documents}`, params);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getAllTermsAndConditions() {
    try {
      const apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_V2.insurer}/${API_ENDPOINTS_V2.termsAndConditions}`, {});
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getGroupDocument(CRN,quoteMasterId,riskType){
    try {
      const apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_V2.quoteSummary}/${API_ENDPOINTS_V2.groupDocument}/${CRN}?quoteMasterId=${quoteMasterId}&riskType=${riskType}`, {});
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getGroupDocumentStatus(CRN,quoteMasterId){
    try {
      const apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_V2.quoteSummary}/${API_ENDPOINTS_V2.groupDocumentStatus}/${CRN}?quoteMasterId=${quoteMasterId}`, {});
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getPlanPremiumDetails(planId: number){
    try{
      const apiResponse = await this.apiService.getFileApiCall(`${API_ENDPOINTS_V2.premium}/export/${API_ENDPOINTS_V2.plan}/${planId}`);
      return apiResponse;
    }catch(exception){
      throw exception;
    }

  }

  

  // async getInsurerBasicDetails(params) {
  //   try {
  //     const apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_V2.user}/${API_ENDPOINTS_V2.insurerBasedBasicDetials}`, params);
  //     return apiResponse;
  //   } catch (exception) {
  //     throw exception
  //   }
  // }

  async getExport(CRN: string, quoteId: number): Promise<any> {
    try {
      const apiResponse = await this.apiService.getFileApiCall(
        `${API_ENDPOINTS_V2.quotes}/excel/${CRN}?quoteId=${quoteId}`
      );
      return apiResponse; // Return the full response (includes data and headers)
    } catch (error) {
      throw error;
    }
  }
  
}
